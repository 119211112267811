
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatPercentage } from '@/utils/number';

/**
 * Show the trend percentage between a previous and current value.
 *
 * Uses arrows and colors to indicate if it's going up, down or remains static.
 */
@Component
export default class ValueTrend extends Vue {
  @Prop({ required: true })
  private previousValue!: number;

  @Prop({ required: true })
  private currentValue!: number;

  /**
   * Indicates how many digits to use for the decimal part of the percentage,
   * if any. Defaults to 2.
   */
  @Prop({ default: 2 })
  private maxFractionDigits!: number;

  /**
   * Message to show when the trend can't be calculated (previous value is 0).
   * Defaults to '-'.
   */
  @Prop({ default: '-' })
  private noTrendMessage!: string;

  private get formattedDifferencePercentage(): string {
    return formatPercentage(Math.abs(this.differencePercentage), this.maxFractionDigits);
  }

  private get differencePercentage(): number {
    const difference = this.previousValue - this.currentValue;
    return difference * 100 / this.previousValue;
  }

  private get hasTrendInfo(): boolean {
    return this.previousValue !== 0;
  }

  private get isTrendGoingUp(): boolean {
    return this.currentValue > this.previousValue;
  }

  private get isTrendStatic(): boolean {
    return this.currentValue === this.previousValue;
  }
}
