/*
 * --------------------------------------------------------------------------------
 * <copyright file="timeFilterToUnitKeyMap.ts" company="Bystronic Laser AG">
 *  Copyright (C) Bystronic Laser AG 2021-2024
 * </copyright>
 * --------------------------------------------------------------------------------
 */

import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';

export const timeFilterToUnitKeyMap: Record<number, string> = {
  [FilterTimeSpanEnum.Day]: 'hours',
  [FilterTimeSpanEnum.Week]: 'days',
  [FilterTimeSpanEnum.Month]: 'days',
  [FilterTimeSpanEnum.Year]: 'months',
};
